<template>
  <div class="app_con">
    <app-list
      ref="applist"
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :editTitle="'巡检管理'"
    >
      <div slot="toolSub" selecTionArr="">
        <el-button type="warning" @click="Staffing" icon="el-icon-upload2"
          >人员配置</el-button
        >
      </div>
    </app-list>
    <el-dialog
      title="巡检人员配置"
      center
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      ><el-form label-width="80px" class="inlineForm">
        <el-form-item label="人员选择">
          <el-select
            @change="creatCanvas"
            multiple
            v-model="form.personId"
            :style="{ width: '270px' }"
          >
            <el-option
              v-for="(item, index) in personList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <div class="flex justify-center align-center">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handelAdd()">提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/meritPatrolManage",
      searchVal: "",
      dialogVisible: false,
      form: { id: null, personId: "" },
      personList: [],
      searchQuery: { num: 0 },
      searchControl: {
        firstSoltSpan: [6, 6, 6, 6],
        secondSoltSpan: [4, 4, 4, 4],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      props: [
        {
          label: "巡检位置",
          prop: "locationName",
          align: "center",
          width: "250px",
        },

        {
          label: "每日巡查次数",
          prop: "everyPatrolNum",
          align: "center",
        },

        {
          label: "分数",
          prop: "score",
          align: "center",
        },
      ],

      formProps: [
        {
          label: "巡检位置",
          prop: "locationId",
          type: "select",
          selectData: [],
        },
        {
          label: "巡检次数",
          prop: "everyPatrolNum",
          type: "input",
        },
        {
          label: "分数",
          prop: "score",
          type: "input",
        },
      ],
    };
  },
  created() {
    this.getPosList();
    this.getpersonList();
  },
  methods: {
    async getPosList() {
      await api.common.list({ apiName: "/meritLocationManage" }).then((res) => {
        let arr = [];
        res.data.data.forEach((element) => {
          arr.push({ label: element.locationName, value: element.id });
        });
        this.formProps[0].selectData = arr;
      });
    },
    creatCanvas() {
      console.log(this.form.personId);
    },
    async handelAdd() {
      let res = await api.common.post({
        apiName: "/meritPatrolManage/configPerson",
        id: this.form.id,
        personId: this.form.personId,
      });
      if (res.code == 200) {
        if (res.code == 200) {
          this.$message({ message: res.message, type: "success" });
          this.dialogVisible = false;
          this.searchQuery.num++;
        } else {
          this.$message(res.message);
        }
      }
    },
    async getpersonList() {
      await api.common.list({ apiName: "/meritPatrolPerson" }).then((res) => {
        this.personList = res.data.data;
        console.log(this.personList, "人员");
      });
    },

    Staffing() {
      let arr = this.$refs.applist.selecTionArr;
      this.form.id = [];
      if (arr.length == 0) {
        this.$message({ message: "请先勾选巡检任务", type: "error" });
        return;
      } else {
        arr.forEach((element) => {
          this.form.id.push(element.id);
        });
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
